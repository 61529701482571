import React, { useMemo } from 'react';

import { LoadableLandingSearchForm } from './LoadableLandingSearchForm';
import { LoadableMainImage } from './LoadableMainImage';
import { HeroBannerProps } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';

export const HeroBanner: React.FC<HeroBannerProps & ClassNameProps> = ({
  cheapestPricePence,
  image,
  title,
  searchConfiguration,
  showSearchForm,
  showMainImage,
}) => {
  return useMemo(
    () => (
      <div
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column-reverse'],
        }}
      >
        {showMainImage && image && (
          <LoadableMainImage
            image={image}
            title={title}
            cheapestPricePence={cheapestPricePence}
            sx={{
              ...(!showSearchForm && { marginTop: ['s', 0] }),
            }}
          />
        )}

        {showSearchForm && (
          <LoadableLandingSearchForm
            searchConfiguration={searchConfiguration}
            sx={{
              marginBottom: [null, 's'],
            }}
          />
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

// eslint-disable-next-line import/no-default-export
export default HeroBanner;
